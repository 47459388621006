import {About,Footer,Header,Skills,Testimonial,Work}from './container';
import {Navbar} from './components';
import './App.scss';
import Contact from './container/Contact/Contact';


function App() {
  return (
    <div className="app">
      <Navbar/>
      <Header/>
      <About/>
      <Work/>
      <Skills/>
      <Testimonial/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
