import React from 'react'
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Contact.scss';

const Contact = () => {
  return (
    <div className='personal__details'>

    <div className="cover__latter">

    Lerato is an eager Junior Java Developer who
    has completed various Courses within the industry relating to Java Development to accomplish his goals:
    To learn and contribute to any given task,
    by putting his computer skills into practice in order to add value to individuals and organizations at large
    </div>

    <div className="contact__details">
       <h4>Personal Details</h4> 
       <p>Name: Lerato</p>
       <p>Initials: L.I</p>
       <p>Surname: Skosana</p>
       <p>Contact Details: 0790310213</p>
       <p>Email Address: leratolsname@gmail.com</p>  
     </div>

     <div className="address__details">
       <h4>Address: </h4>
       <p>Street: 1084 Koma Street</p>
       <p>Suburb: Klipspruit</p>
       <p>Place: Soweto</p>
       <p>City: Johanessburg</p>
       <p>Postal Code: 1809</p>
     </div>
 </div>
  )
}



export default AppWrap(
  MotionWrap(Contact, 'app__contact'),
  'contact',
  'app__whitebg',
);