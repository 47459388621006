import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: '44bgl8kp',
  dataset: 'production_p',
  apiVersion: '2023-09-01',
  useCdn: true,
  token: 'skXLGOctkNPEvrxD7d7AiqpjoGtRGhaY1pG08GJTYnbU5cDvwyjcsbBbMND2scus2PzqAvOPI6op5BrVOesdQefLJwiyzy5rA2w0s1bn7ygmjoOWmmYyGmr8OGfx0FmWMr5JePAOmSk71BBKF4KpVxyhKlEpEgMy1CaspGeeWcTN7R0M1yMF',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);