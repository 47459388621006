import React,{useState,useEffect} from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import {urlFor, client} from '../client'

const SocialMedia = () => {

  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  return(
    <>
    {filterWork.map((work,index)=>(
      <div className="app__social">
        

          <div>
          <a href={work.projectLink} target="blank" rel='noreferrer'>
            <FaFacebookF />
          </a>
          </div>
          <div>
            <a href={work.instagramLink} target="blank" rel='noreferrer'>
              <BsInstagram />
            </a>
          </div>
          <div>
          <a href={work.twitterLink} target="blank" rel='noreferrer'>
            <BsTwitter />
          </a>
          </div>
     </div>
    ))
    }
    </>
  )
};

export default SocialMedia;
